import React, { useEffect, useState, useRef  } from "react";
import {Row, Col} from "react-bootstrap";
// import Chatbot, { createClientMessage, createChatBotMessage, createCustomMessage } from "react-chatbot-kit";
import Dropdown from 'react-bootstrap/Dropdown';
import createMessageParser from "../pages/chatbot/MessageParse";
import CustomMessage from "./chatbot/CustomMessage";
import MessageContainer from "./chatbot/MessageContainer";
import createActionProvider from "../pages/chatbot/ActionProvider";
import { getChatHistory, deleteSession, postMessage, getSocket } from "../utils/api";

import 'bootstrap/dist/css/bootstrap.min.css';
import config from "./chatbot/chatbotConfig";
import {useNavigate, useParams} from "react-router";
import { Button } from "react-bootstrap";
import BotAvatar from "./chatbot/BotAvatar"
import ChatIcon from './chatbot/send-button.png';


const ChatContainer = (props) => {
    const {session_id, JWT} = useParams()
    const navigate = useNavigate()
    const {courses, email} = props
    const [currCourse, setCurrCourse] = useState();
    const [currCourseName, setCurrCourseName] = useState();
    const [selectedSession, setSelectedSession] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const messagesRef = useRef([]);
    const disableInputRef = useRef(false)
    const loadingReplyRef = useRef(false)
    const [forceUpdate, setForceUpdate] = useState(0); 
    const [inputValue, setInputValue] = useState("");
    const placeholder = "Write your message here"
    // const [messageParser, setMessageParser] = useState(null);
    // const email = localStorage.getItem("email");

    const deleteChat = () => {
        deleteSession(session_id).then(res => {
            if (res.data.result) {
                navigate(`/chatbot/${JWT}`)
            } else {
                console.log(res.data)
            }
        })
    }

    useEffect(() => {
        if (courses.length !== 0) {
            setCurrCourseName(courses[0][1])
            setCurrCourse(courses[0][0])
        }
    }, [courses])

    useEffect(() => {
        setIsLoading(true);
        // Fetch chat history when the component mounts

        if (session_id !== undefined) {
            getChatHistory(session_id)
                .then(res => {
                    // Create a new array to hold the updated messages
                    let newMessages = [];

                    // Assuming the endpoint returns an array of messages
                    let mKey = 0;
                    res.data.chat_history.forEach(message => {
                        newMessages.push({key: mKey, type: "user", content: message[1]});
                        mKey++
                        newMessages.push({key: mKey, type: "bot", content: message[2]});
                        mKey++
                    });
                    //setMessages(newMessages)
                    messagesRef.current = newMessages
                    const selected_session = res.data.session_info
                    setSelectedSession(selected_session)
                    setCurrCourse(selected_session[2])
                    setCurrCourseName(selected_session[1])
                    // Update the config state once with the new messages
                    setIsLoading(false);
                }).catch(error => {
                    setIsLoading(false);
                    if (error.response && error.response.status === 404) {
                        // Handle 404 error
                        console.error("Error: ", error.response.data.detail);
                        // You might set some state here to show an error message in your UI
                        console.log(`Session Not Found!`)
                    } else {
                        // Handle other errors
                        console.error("An unexpected error occurred: ", error);
                        console.log(`"An unexpected error occurred. Please try again later."`)
                        navigate(`/chatbot/${JWT}`)
    
                    }
                });
        } else {
            if (courses.length !== 0 && currCourse === undefined) {
                setCurrCourse(courses[0][0])
                setCurrCourseName(courses[0][1])
            }
            setIsLoading(false)
            messagesRef.current = []
        }   
    }, [session_id]);


    useEffect(() => {
        if (session_id === undefined) {
            setMessages([])
            setIsLoading(false)
        }
    }, [session_id])


    // const wsRef = useRef(null);  // Reference to manage the WebSocket connection


    const handleSubmit = (e) => {
        e.preventDefault();
        disableInputRef.current = true
        const userChat = { key: messagesRef.current.length, type: "user", content: inputValue };
        messagesRef.current.push(userChat)
        loadingReplyRef.current = true;

        const ws = new WebSocket(getSocket(email));


        ws.onopen = (event) => {
            const messageData = {
                user_input: inputValue,
                user_id: email,
                session_id: session_id ? session_id : "",
                course_id: currCourse
            };
            ws.send(JSON.stringify(messageData));
        };

        ws.onmessage = (event) => {
            const data = event.data;


            try {
                const jsonData = JSON.parse(data);
                if (jsonData.session_id !== undefined) {
                    if (jsonData.session_id !== session_id) {
                        // Handle the session ID here
                        navigate(`/chatbot/${JWT}/${jsonData.session_id}`)
                    }
                    return
                }
            } catch (error) {
                // Not a JSON, continue processing as a token
            }

            if (data === "END_OF_RESPONSE") {
                ws.close();
            } else {
                loadingReplyRef.current = false
                const lastMessage = messagesRef.current[messagesRef.current.length - 1];
                if (lastMessage && lastMessage.type === "bot") {
                    lastMessage.content += data;
                } else {
                    messagesRef.current.push({ key: messagesRef.current.length, type: "bot", content: data});
                }
                setForceUpdate(prev => prev + 1); // Force a re-render
            }
            
        };

        ws.onerror = (error) => {
            console.error("WebSocket Error: ", error);
        };

        ws.onclose = (event) => {
            disableInputRef.current = false
            loadingReplyRef.current = false
            if (event.wasClean) {
                //console.log(`Connection closed cleanly, code=${event.code}, reason=${event.reason}`);
            } else {
                console.error("Connection died");
            }
        };

        setInputValue("");
    }

    const stripHtml = (html) => {
        // Create a new div element
        const tempDivElement = document.createElement("div");
        // Set the HTML content with the provided
        tempDivElement.innerHTML = html;
        // Replace HTML entities with their text equivalents
        const text = tempDivElement.textContent || tempDivElement.innerText || "";
        return text.replace(/&[#A-Za-z0-9]+;/gi, (entity) => {
            const textarea = document.createElement('textarea');
            textarea.innerHTML = entity;
            return textarea.value;
        });
    };
    

    const saveChat = () => {
        // Convert chat messages to a string
        let chatContent = messagesRef.current.map(msg => {
            let content = msg.type === 'bot' ? stripHtml(msg.content) : msg.content;
            return `${msg.type === 'user' ? 'User' : 'Bot'}: ${content}`;
        }).join('\n');
    
        // Create a blob from the chat content
        const blob = new Blob([chatContent], { type: 'text/plain' });
    
        // Create a link element for the download
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = 'chat_history.txt'; // Name of the file to be downloaded
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    


    if (isLoading) {
        return (
            <div className="react-chatbot-kit-chat-container">
                <div className="react-chatbot-kit-chat-inner-container">
                    <div className="react-chatbot-kit-chat-header">
                        <Button variant="light">Loading</Button>
                    </div>
                    <div className="react-chatbot-kit-chat-message-container"/>
                    <div className="react-chatbot-kit-chat-input-container">
                        <form
                            className="react-chatbot-kit-chat-input-form"
                            onSubmit={handleSubmit}
                        >
                            <input
                            className="react-chatbot-kit-chat-input"
                            placeholder={placeholder}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            />
                            <button
                            className="react-chatbot-kit-chat-btn-send"
                            disabled={true}
                            >
                            <img src={ChatIcon} className="react-chatbot-kit-chat-btn-send-icon" />
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        );
    } else {
        return(
            <div className="react-chatbot-kit-chat-container">
                <div className="react-chatbot-kit-chat-inner-container">
                    {
                        session_id?
                        (<div className="react-chatbot-kit-chat-header">
                            <Row className="react-chatbot-kit-chat-header-row">
                                <Col xs={12} md={8}><div className="react-chatbot-kit-chat-header-title">{selectedSession[0]} | {selectedSession[1]} | {selectedSession[3]}</div></Col>
                                <Col xs={6} md={4}><Button onClick={saveChat} className="save-chat-button">Save Chat</Button><Button className="delete-session-button" variant="light" onClick={deleteChat}>Delete Session</Button></Col>
                                {/* <Col xs={6} md={4}></Col> */}
                            </Row>
                        </div>)
                        :
                        (<div className="react-chatbot-kit-chat-header">
                            <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {currCourseName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    courses.map(course => (
                                        <Dropdown.Item onClick={() => {
                                            setCurrCourse(course[0])
                                            setCurrCourseName(course[1])
                                        }} key={course[0]}>{course[1]}</Dropdown.Item>
                                    ))
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        </div>
                        )
                    }

                    <MessageContainer messages={messagesRef.current} email={email} loadingReply={loadingReplyRef.current}/>
                    <div className="react-chatbot-kit-chat-input-container">
                        <form
                            className="react-chatbot-kit-chat-input-form"
                            onSubmit={handleSubmit}
                        >
                            <input
                            className="react-chatbot-kit-chat-input"
                            placeholder={placeholder}
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            />
                            <button
                            className="react-chatbot-kit-chat-btn-send"
                            disabled={disableInputRef.current}
                            >
                            <img src={ChatIcon} className="react-chatbot-kit-chat-btn-send-icon" />
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}


export default ChatContainer;