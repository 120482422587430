import React, { useEffect, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import '../pages/chatbot/main.css';
import '../pages/chatbot/customize.css'

import ChatContainer from "./ChatContainer";
import { getSessionID, GetRequest, LinqBackEnd } from "../utils/api";
import { ListGroup, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";

import external_data from "../mockData";


const ChatBot = () => {
    // external params
    const {indexed_courses} = external_data
    const navigate = useNavigate()
    const {session_id, JWT} = useParams()

    const [sessionList, setSessionList] = useState([]);
    const [activeSession, setActiveSession] = useState("");
    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(true)
    const [email, setEmail] = useState("")
    const [noAuth, setNoAuth] = useState(false)
    const [longList, setLongList] = useState(false)


    const fetchCourses = (JWT, callback) => {
        if (JWT === null) {
            console.log("User Authorization Failed");
            callback("User Authorization Failed", null);
        }
        GetRequest("courses/enrolled", {
            headers: {Authorization: `JWT ${JWT}`},
        })
            .then((res) => {
                if (!res) {
                    console.log("Network Error");
                    callback("Network Error", null);
                } else if (res && res.status === 200) {
                    callback(null, res.data);
                } else if (res.data) {
                    callback(res.data.err, null);
                }
            })
            .catch((error) => {
                console.log("get user info error: ", error);
                callback(error, null);
            });
    };



    const fetchSessionIDList= async () => {
        try {
            const res = await getSessionID(email);
            const session_list = res.data.session_list;
            setSessionList(session_list);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.log("new dialog")
            } else {
                console.log(error)
            }
        }
    };


    useEffect(() => {
        fetchCourses(JWT, (err, data) => {
            if (err) {
                console.log(err)
                setNoAuth(true)
            } else {
                if (data.email && data.courses) {
                    setNoAuth(false)
                    let course_list = []
                    for (let course in data.courses) {
                        if (indexed_courses.includes(data.courses[course]["course_id"].toString())) {
                            course_list.push([data.courses[course]["course_id"].toString(), data.courses[course]["course_code"]])    
                        }
                    }
                    setCourses(course_list)
                    setEmail(data.email)
                    setLoading(false)
                } else {
                    setNoAuth(true)
                }
            }
        });
    }, [session_id, JWT])


    useEffect(() => {
        fetchSessionIDList();
        if (session_id !== undefined) {
            setActiveSession(session_id)
        } else {
            setActiveSession("")
        }
    }, [email, session_id])

    useEffect(() => {
        if (sessionList.length >= 5) {
            setLongList(true)
        }
    })

    function toNewChat(e) {
        navigate(`/chatbot/${JWT}`)
    }

    function toChatSession(s_ID) {
        navigate(`/chatbot/${JWT}/${s_ID}`)
    }

    return(
        <div className="ChatBot">
            {
                loading?
                (<div className="loading-spinner">
                    {
                        noAuth?
                        (<>
                        <Row>Authorization expired!</Row>
                        <Row>Please return to your Oxygen panel and obtain new auth token.</Row></>)
                        :
                        (<>                    
                        <Row><Spinner animation="border"></Spinner></Row>
                        <Row>Loading Your AI Course Assistant...</Row></>)
                    }

                </div>)
                :
                (<><div className="left-side-bar">

                <div className="session-container">
                    <ListGroup>
                        <ListGroup.Item><Button variant="dark" onClick={toNewChat}>New Chat</Button></ListGroup.Item>
                        {
                            longList?
                            (<>
                            <ListGroup.Item className="long-list-note">
                            {/* <Row>Please note that session inactive for a week will be removed.</Row> */}
                            {/* <br></br> */}
                            <Row>Click save chat to save your conversation with the AI course assistant</Row>
                            </ListGroup.Item></>)
                            :
                            <></>
                        }
                    </ListGroup>
                </div>


                <div className="session-list-div">
                    <ListGroup>
                        {
                            sessionList.map(session => (
                                <ListGroup.Item
                                    key={session[2]}
                                    onClick={() => toChatSession(session[2])}
                                    className={`custom-list-item ${activeSession === session[2] ? 'active' : ''}`}>
                                    <Row className="session-title "><b>{session[1]}  {session[0]}</b></Row>
                                    <br/>
                                    <Row className="session-title">{session[3]}</Row>
                                </ListGroup.Item>
                            ))
                        }

                    </ListGroup>
                </div>
            </div>
            <div className="right-side-container">
                <ChatContainer email={email} courses={courses}/>
            </div></>)
            }
            
        </div>
    )
}

export default ChatBot