import logo from './logo.svg';
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ChatBot from './pages/ChatBot'


function App() {
  return (
      <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path='/chatbot/:JWT' element={<ChatBot/>} />
                    <Route path='/chatbot/:JWT/:session_id' element={<ChatBot/>} />
                </Routes>
            </BrowserRouter>
      </div>
  );
}

export default App;