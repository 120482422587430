

const config = {
  initialMessages: [],

  // customComponents: {     // Replaces the default header
  //   header: () => <div style={{ backgroundColor: 'red', padding: "5px", borderRadius: "3px" }}>This is the header</div>
  // }
}

export default config