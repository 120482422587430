import React, {useState, useEffect, useRef} from "react";
import UserIcon from '../chatbot/user-alt.svg'
import Spinner from 'react-bootstrap/Spinner';


export default function MessageContainer(props) {
    const [messages, setMessages] = useState([])
    const [loadingReply, setLoadingReply] = useState(false)
    const [email, setEmail] = useState("U")

    useEffect(() => {
        // console.log("scroll")
        setMessages(props.messages)
    }, [props.messages])

    useEffect(() => {
        setLoadingReply(props.loadingReply)
    }, [props.loadingReply])

    useEffect(() => {
        if (props.email !== undefined) {
            setEmail(props.email[0].toUpperCase())
        }
    }, [props.email])

    useEffect(() => {
        // console.log("scroll")
        if (containerRef.current) {
            const container = containerRef.current;
            container.scrollTop = container.scrollHeight;
        }
    }, [messages])

    useEffect(() => {
        // console.log("scroll");
        if (containerRef.current && messages && messages.length > 0) {
            const container = containerRef.current;
            container.scrollTop = container.scrollHeight;
        }
    }, [messages && messages.length > 0 ? messages[messages.length - 1].content : null]);


    const containerRef = useRef(null);


    return (
        <div className="react-chatbot-kit-chat-message-container" ref={containerRef}>
            {
                messages.map(message => (
                    message.type === "user"?
                    (<div key={message.key} className="react-chatbot-kit-user-chat-message-container">
                        <div className="react-chatbot-kit-user-chat-message">
                            <div className="react-chatbot-kit-user-chat-message-arrow"/>
                            <span>{message.content}</span>
                        </div>
                        <div className="react-chatbot-kit-user-avatar">
                            <div className="react-chatbot-kit-user-avatar-container">
                                <p className="react-chatbot-kit-chat-user-avatar-letter">{email[0]}</p>
                            </div>
                        </div>
                    </div>)
                    :
                    (<div key={message.key} className="react-chatbot-kit-chat-bot-message-container">
                        <div className="react-chatbot-kit-chat-bot-avatar-icon"/>
                        <div className="react-chatbot-kit-chat-bot-message">
                            <div className="react-chatbot-kit-chat-bot-message-arrow"/>
                            <span>
                                <div dangerouslySetInnerHTML={{__html: message.content}}/>
                            </span>
                        </div>
                    </div>)
                ))
            }
            {
                loadingReply?
                (
                <div className="react-chatbot-kit-chat-bot-message-container">
                    <div className="react-chatbot-kit-chat-bot-avatar-icon"/>
                    <div className="react-chatbot-kit-chat-bot-message">
                        <div className="react-chatbot-kit-chat-bot-message-arrow"/>
                        <span className="loading-span">
                            <Spinner className="message-loading" animation="grow" />
                            <Spinner className="message-loading" animation="grow" />
                            <Spinner className="message-loading" animation="grow" />
                        </span>
                    </div>
                </div>)
                :
                (<></>)
            }
        </div>
    )
}