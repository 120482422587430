import axios from 'axios';

const baseWS = 'wss://kepler.linq.ai/kepler_api/ws'
// const baseWS = 'ws://192.168.1.96:8000/kepler_api/ws'

export const ChatBotBackEnd = axios.create({
    timeout: 50000,
    // baseURL: 'http://192.168.1.96:8000/kepler_api',
    // baseURL: 'http://10.0.0.119:8000/kepler_api',
    baseURL: 'https://kepler.linq.ai/kepler_api',
});


// Linq backend api for course fetching and JWT authorization check
export const LinqBackEnd = axios.create({
    timeout: 50000,
    baseURL: 'https://api.oxygen.linq.ai:3020/api/'
})


export const GetRequest = (router, payload, headers = null) => {
    const backendRequestUrl = "https://api.oxygen.linq.ai:3020/api/";
    return axios.get(backendRequestUrl + router, payload, headers)  // TODO
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err.response
        })
  }


export function getSocket(user_id) {
    return baseWS + `/get_response/${user_id}`
}


export function getSessionID(user_id) {
    return ChatBotBackEnd.get(`/getAllSessions/`, {
        params: {
            user_id: user_id 
           }
    });
}

export function getSessionInfo(session_id) {
    return ChatBotBackEnd.get(`/getSessionInfo/`, {
        params: {
            session_id: session_id
        }
    });
}


export function getChatHistory(session_id) {
    return ChatBotBackEnd.get(`/getChatHistory/`, {
        params: {
             session_id: session_id 
            }
    });
}

export function postMessage(message, email, session_id, course_id) {
    return ChatBotBackEnd.post('/search/', {
        user_input: message,
        user_id: email,
        session_id: session_id,
        course_id: course_id
    }, {
        headers: {
            "Content-Type": "application/json",
        }
    });
}
 
export function deleteSession(session_id) {
    return ChatBotBackEnd.delete(`/deleteSession/`, {
        params: {
             session_id: session_id 
            }
    });
}
